.loginWelcome {
  font-size: 1.6rem;
  text-align: center;
}

.loginForm {
  width: 100%;
  display: flex;
  flex-flow: column nowrap;

  .inline {
    display: inline-flex;
    justify-content: space-between;
    color: var(--color-txtLight);
    font-size: 1.6rem;
    margin: 1.6rem 0 3.2rem 0;

    a {
      text-decoration: none;
      color: unset;
    }
  }
}

.errorBoxContainer {
  height: 10rem;
  width: 100%;
  margin-top: 3.2rem;
}

.registerLink {
  text-align: center;
  align-self: center;
  color: var(--color-txtLight);

  a {
    color: var(--color-orange);
    text-decoration: none;
  }
}
