.formGroup {
  composes: formGroup from "../../styles/form.scss";
  border: 1px dashed #cdd1e4;
  outline: 0;
  height: unset;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin: unset;
  padding: 3.7rem 5rem;
}

.formField {
  composes: formField from "../../styles/form.scss";
}

.uploadImage {
  width: 100px;
  display: block;
  margin: 0 auto 2.5rem auto;
}

.uploadImage[data-shake="1"] {
  animation: shake 0.3s;
}

@keyframes shake {
  0% {
    transform: translateX(0px);
  }
  20% {
    transform: translateX(-5px);
  }
  40% {
    transform: translateX(0px);
  }
  60% {
    transform: translateX(5px);
  }
  80% {
    transform: translateX(0px);
  }
  100% {
    transform: translateX(-5px);
  }
}

.infoMessage {
  font-size: 1.4rem;

  p {
    margin: 0;
  }
}
