.ProfileMenuWrapper {
  display: flex;
  align-items: center;
  width: fit-content;
  height: 100%;

  .text {
    display: block;
    text-align: right;

    .user {
      font-size: 1.4rem;
      font-weight: 500;
      margin: 0;
      color: var(--color-txtLight);
    }
  }

  .ProfileImgPlaceholder {
    border-radius: 100%;
    width: 4rem;
    height: 4rem;
    margin-left: 2.4rem;
  }
}
