.previewCard {
  background-color: var(--color-background);
  border-radius: 8px;
  width: 336px;

  .imageRow {
    width: 100%;
    height: 40%;
    position: relative;

    .actions {
      display: flex;
      position: absolute;
      right: 0;
      top: 16px;
      img {
        cursor: pointer;
        margin-right: 16px;
      }
    }

    .routeImage {
      width: 100%;
      border-radius: 8px 8px 0px 0px;
      height: 200px;

      object-fit: cover;
    }
  }

  .contentWrapper {
    position: relative;
    padding: 40px 24px;
    height: 60%;
    .infoRow {
      .infoHeading {
        h1 {
          font-size: 2em;
        }

        h2 {
          color: var(--color-txtLight);
          font-size: 1.6em;
          font-weight: normal;
          margin: 16px 0;
          overflow: hidden;
          text-overflow: ellipsis;
          line-height: 120%;
          display: -webkit-box;
          -webkit-line-clamp: 2; /* number of lines to show */
          -webkit-box-orient: vertical;
        }
      }

      RouteInfoList {
        margin: 32px 0;
      }
    }

    .buttonRow {
      overflow: hidden;
      width: 100%;
      .left {
        float: left;
      }
      .right {
        float: right;
      }
    }
  }
}
