/* Fonts - Open Sans */

@font-face {
  font-family: "opensans";
  src: url("../assets/fonts/opensans/OpenSans-Regular.woff2") format("woff2"),
    url("../assets/fonts/opensans/OpenSans-Regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "opensans";
  src: url("../assets/fonts/opensans/OpenSans-Light.woff2") format("woff2"),
    url("../assets/fonts/opensans/OpenSans-Light.woff") format("woff");
  font-weight: lighter;
  font-style: normal;
}

@font-face {
  font-family: "opensans";
  src: url("../assets/fonts/opensans/OpenSans-Semibold.woff2") format("woff2"),
    url("../assets/fonts/opensans/OpenSans-Semibold.woff") format("woff");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "opensans";
  src: url("../assets/fonts/opensans/OpenSans-Bold.woff2") format("woff2"),
    url("../assets/fonts/opensans/OpenSans-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
}

/* Fonts - Futura */

@font-face {
  font-family: "futura";
  src: url("../assets/fonts/futura/futura.woff2") format("woff2"),
    url("../assets/fonts/futura/futura.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "futura";
  src: url("../assets/fonts/futura/futura-light.woff2") format("woff2"),
    url("../assets/fonts/futura/futura-light.woff") format("woff");
  font-weight: lighter;
  font-style: normal;
}

@font-face {
  font-family: "futura";
  src: url("../assets/fonts/futura/futura-medium.woff2") format("woff2"),
    url("../assets/fonts/futura/futura-medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "futura";
  src: url("../assets/fonts/futura/futura-bold.woff2") format("woff2"),
    url("../assets/fonts/futura/futura-bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
}

/* Text */

h1 {
  margin: 0;
  padding: 0;
  font-size: 1.7rem;
}

.heavyTitle {
  font-size: 1.8rem;
  font-weight: bold;
  font-family: futura;
  color: var(--color-black);
  text-decoration: none;
}

.mediumTitle {
  font-size: 2rem;
  font-weight: 500;
  font-family: futura;
  color: var(--color-black);
}

.smallTitle {
  font-size: 1.6rem;
  font-weight: 500;
  font-family: futura;
}

.mainTxt {
  font-size: 1.6rem;
  font-weight: 400;
  color: var(--color-black);
}

.mainTxt__heavy {
  font-size: 1.6rem;
  font-weight: 500;
  color: var(--color-black);
}

.subText {
  font-size: 1.5rem;
  font-weight: normal;
  color: var(--color-txtLight);
}

.futuraText {
  font-weight: 500;
  font-family: futura;
  color: var(--color-white);
  margin: 0;
}

.futuraTextBold {
  font-weight: bold;
  font-family: futura;
  color: var(--color-white);
}

.validationText {
  font-size: 1.4rem;
  font-weight: 400;
  color: var(--color-error);
}
