.loading {
  text-align: center;
  font-size: 1.6rem;
}

.loading h1 {
  margin: 0;
}

.error {
  text-align: center;
  font-size: 1.6rem;
}

.error a {
  text-decoration: underline;
  color: unset;
}

.confirmation {
  text-align: center;
}

.confirmation img {
  width: 75px;
  margin: 15px;
}

.back {
  margin: 15px 0;
}
