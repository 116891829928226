.mainContainer {
  height: 100%;
  display: grid;
  grid-template-columns: 2fr 1fr;

  .imagePanel {
    height: 100%;
    width: 100%;
    position: relative;
    background-image: url(../../assets/img/motor2_small.jpg);
    background-size: cover;
    background-position: center;

    .quoteContainer {
      height: 30rem;
      width: 100%;
      display: flex;
      flex-flow: column nowrap;
      justify-content: center;
      position: absolute;
      bottom: 0;
      padding: 7.2rem;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #2b0b00 100%);

      .quoteInnerContainer {
        max-width: 60rem;

        .quoteText {
          font-size: 2.2rem;
          margin-bottom: 3rem;
        }

        .quoteRole {
          opacity: 0.5;
        }
      }
    }
  }

  .formPanel {
    min-width: 40rem;
    height: 100vh;
    overflow-y: auto;
    padding: 0 2.5rem;
    display: grid;
    grid-template-rows: 20rem 1fr 6.4rem;
    grid-template-areas:
      "logo"
      "form"
      "footer";

    .mainLogo {
      grid-area: logo;
      width: 25rem;
      align-self: center;
      justify-self: center;
    }

    .formContainer {
      grid-area: form;
      margin: 0 auto;
      width: 100%;
      max-width: 45.6rem;
    }

    .loginFooter {
      grid-area: footer;
      display: flex;
      justify-content: space-evenly;
      align-items: center;

      .footerTxt {
        font-size: 1.4rem;
        text-decoration: none;
        color: var(--color-txtLight);
      }
    }
  }
}

@media only screen and (max-width: 750px) {
  .mainContainer {
    grid-template-columns: unset;

    .imagePanel {
      display: none;
    }

    .formPanel {
      min-width: unset;
    }
  }
}
