.formGroup {
  composes: formGroup from "../../styles/form.scss";
  height: fit-content;
}

.formField {
  composes: formField from "../../styles/form.scss";
  resize: none;
}

.formField_label {
  composes: formField_label from "../../styles/form.scss";
}
