/* Text Input */
.formGroup {
  position: relative;
  margin: 1.6rem 0;
  padding: 2rem 1.6rem;
  width: 100%;
  height: 7.2rem;
  border: 0.07rem solid var(--color-inputBorder);
  background: transparent;
  border-radius: 8px;
  transition: border-color 0.2s;
}

.formGroup:focus-within {
  border-color: var(--color-orange);
}

.formField {
  composes: mainTxt from "./typo.scss";
  width: 100%;
  border: 0;
  outline: 0;
  font-size: 1.6rem;
  color: var(--color-black);
  margin-top: 12px;
  background: transparent;
  z-index: 2;
}

/* autocomplete fix */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
  -webkit-text-fill-color: black !important;
}

.formField::placeholder {
  color: transparent;
  z-index: -1;
}

.formField:placeholder-shown ~ .formField_label {
  font-size: 1.6rem;
  cursor: text;
  top: 26px;
}

.formField_label {
  position: absolute;
  top: 13px;
  display: block;
  transition: 0.2s;
  font-size: 1.4rem;
  color: var(--color-txtLight);
  user-select: none;
  z-index: 1;
}

.formField:focus ~ .formField_label {
  position: absolute;
  top: 13px;
  display: block;
  transition: 0.2s;
  font-size: 1.4rem;
  z-index: 1;
}

.formField:required,
.formField:invalid {
  box-shadow: none;
}

.formValidation {
  position: relative;
  composes: validationTxt from "./typo.scss";
  margin-top: 2.6rem;
}
