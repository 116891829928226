:root {
  --color-txtLight: #6a6e7c;
  --color-input: #b4bbc9;
  --color-inputBorder: #cdd1e4;
  --color-placeholder: #c5cedf;
  --color-frame: #f0f2f6;
  --color-background: #f8f9fd;
  --color-white: #ffffff;
  --color-black: #000000;
  --color-drank: #638bf2;
  --color-tankstation: #ff4e53;
  --color-erfgoed: #8840e4;
  --color-eten: #ff6fab;
  --color-natuur: #56cb54;
  --color-amusement: #91ecf2;
  --color-valid: #7ed321;
  --color-orange: #ff4507;
  --color-error: #ff0e0e;
}
