.navHeader {
  height: 7.2rem;
  width: 100%;
  display: flex;
  align-items: center;
  background-color: var(--color-white);
  border-bottom: 0.1rem solid var(--color-frame);
  padding: 0 2.4rem;
}

.listSwitch {
  cursor: pointer;
  color: var(--color-black);
  opacity: 0.3;
  margin-right: 4rem;
  padding-bottom: 0.7rem;
  border-bottom: 0.3rem solid rgba(0, 0, 0, 0);
}

.listSwitchActive {
  border-bottom: 0.3rem solid var(--color-black);
  opacity: 1;
}

.createRouteButton {
  margin-left: auto;
}
