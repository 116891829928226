.list {
  width: 100%;
  height: auto;
  font-size: 1.5em;
  color: var(--color-txtLight);
  position: relative;
  overflow: hidden;
  display: grid;

  .listItem {
    display: flex;
    align-items: center;
    width: 100%;

    .imageWrapper {
      width: 35px;
      margin-right: 1em;
      height: 25px;

      img {
        height: 25px;
      }
    }
  }

  .listItem:nth-of-type(odd) {
    float: left;
  }

  .listItem:nth-of-type(even) {
    float: right;
  }
}
