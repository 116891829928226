.mapMenu {
  grid-area: mapMenu;
  position: relative;
  height: 100%;
  width: 100%;

  .overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.1);
    z-index: 100;
  }

  .filters {
    position: absolute;
    z-index: 2;
    left: 24px;
    top: 24px;
    display: grid;
    grid-template-columns: repeat(3, max-content);
    gap: 0 24px;
  }

  .preview {
    position: absolute;
    z-index: 1;
    left: 24px;
    top: 120px;
  }
}
