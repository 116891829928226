.infoCardWrapper {
  min-height: 150px;
  height: auto;
  position: relative;
  border-bottom: 1px solid var(--color-frame);

  .box {
    position: absolute;
    width: 52%;
    background-color: var(--color-white);
    height: 100%;
    z-index: 1;
    margin-left: -10px;
    transform: skewX(-15deg);
  }

  .content {
    z-index: 1;
    position: absolute;
    height: 100%;
    width: 55%;
    padding: 15px;
    display: flex;
    flex-flow: wrap;
    justify-content: space-between;

    h1 {
      font-size: 1.7rem;
      width: 100%;
      font-family: futura;
    }
  }

  .image {
    img {
      height: 100%;
      width: 55%;
      object-fit: cover;
      position: absolute;
      right: 0;
    }
  }
}
