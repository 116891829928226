.home {
  height: 100vh;
  width: 100vw;
  display: grid;
  grid-template-columns: 46.4rem 1fr;
  grid-template-rows: 7.2rem 1fr;
  grid-template-areas:
    "sideMenu topMenu"
    "sideMenu mapMenu";
}
