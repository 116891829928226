.imageBox {
  position: relative;
}

.image {
  object-fit: cover;
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: 8px;
}

.deleteImage {
  cursor: pointer;
  width: 50px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
