.back {
  margin: 15px 0;
}

.confirmation {
  text-align: center;
}

.confirmation img {
  width: 75px;
  margin: 15px;
}

h1 {
  font-size: 2rem;
}

p {
  font-size: 1.6rem;
}
