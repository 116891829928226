.dropdownWrapper {
  position: relative;
  z-index: 5;

  .dropdown {
    display: flex;
    align-items: center;
    width: auto;
    border: 1px solid var(--color-inputBorder);
    border-radius: 24px;
    background-color: var(--color-white);
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.08);
    padding: 15px;
    min-width: 100px;
    position: relative;
    cursor: pointer;

    .imageWrapper {
      width: calc(100% - 30px);
      height: 20px;
      position: absolute;
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-between;
      top: 50%;
      transform: translateY(-50%);

      #left {
        height: 100%;
      }
    }

    span {
      width: 100%;
      margin: 0 40px;
      text-align: center;
      font-size: 15px;
    }
  }
}
