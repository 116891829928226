.routeForm {
  background-color: var(--color-white);
  height: calc(100vh - 8.8rem);
  display: grid;
  grid-template-rows: 1fr 8.8rem;

  .formFields {
    padding: 1.6rem 2.4rem;
    overflow-y: auto;

    .imageBoxes {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-auto-rows: 195px;
      grid-gap: 2.4rem;
      margin-top: 1.6rem;

      .imageUpload {
        padding: unset;

        img {
          width: 50px;
        }

        p {
          color: var(--color-inputBorder);
          margin-top: 10px;
        }
      }

      .imageUpload:before {
        content: "";
        float: left;
        padding-top: 100%;
      }
    }
  }
}
