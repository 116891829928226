ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

button {
  border: none;
  outline: none;
  cursor: pointer;
}

figure {
  margin: 0;
  line-height: 0;
}

.visuallyhidden {
  display: none;
}

.unauthorized {
  font-size: 2.6rem;
  font-weight: bold;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* Buttons */

.primaryCTA {
  font-size: 1.4rem;
  font-weight: 500;
  color: var(--color-white);
  background-color: var(--color-orange);
  padding: 1.3rem 1.6rem;
  border: none;
  border-radius: 6px;
  box-shadow: 0px 0px 8px rgba(255, 69, 7, 0.5);
}

.secondaryCTA {
  font-size: 1.2rem;
  font-weight: 400;
  color: var(--color-black);
  background-color: transparent;
  padding: 0.9rem 1.3rem;
  border: 0.12rem solid var(--color-black);
  border-radius: 4px;
}

.primaryCTA,
.secondaryCTA {
  transition: transform 0.15s ease-in-out;
  width: fit-content;
  height: 4rem;
  text-decoration: none;
}

.primaryCTA:active,
.secondaryCTA:active {
  transform: scale(0.96);
}

.tertiaryCTA {
  font-size: 1.4rem;
  font-weight: 500;
  font-family: "opensans", sans-serif;
  color: var(--color-txtLight);
  padding: 0;
  line-height: normal;
  background-color: transparent;
}

.iconCTA,
.backCTA {
  display: block;
  background-color: transparent;
}

.backCTA {
  width: 2rem;
  height: 2rem;
  background: url(../assets/icons/backArrow.svg) center no-repeat;
}

.buttonBox {
  height: 8.8rem;
  width: 100%;
  background-color: var(--color-white);
  padding: 2rem 2.4rem;
  border-top: 0.1rem solid var(--color-frame);
  z-index: 1;

  * {
    width: 100%;
    height: 100%;
  }
}
