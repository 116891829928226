.waypointElementWrapper {
  width: 100%;
  padding: 0.4rem 2.4rem;
  display: flex;
  align-items: center;
  justify-content: baseline;
  height: 8rem;

  .internalWrapper {
    width: 100%;
    padding: 0.8rem;
    display: flex;
    align-items: center;
    justify-content: baseline;
    transition: all 0.2s;
    border-radius: 8px;
    margin-left: 12px;

    .icon {
      min-width: 56px;
      min-height: 56px;
      margin-right: 30px;
      background-color: var(--color-background);
      border-radius: 16px;
      display: flex;
      justify-content: center;
    }

    .text {
      font-size: 1.5rem;
      white-space: nowrap;
      width: 100%;
    }

    .lat,
    .lng {
      display: inline-block;
      white-space: normal;
      width: 50%;
    }
  }

  .focussed {
    background-color: var(--color-background);
  }
}
