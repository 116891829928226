.list {
  display: grid;
  height: calc(100vh - 25.5rem);
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 8.8rem;

  .waypoints {
    background-color: var(--color-white);
    overflow-y: auto;
  }
}
