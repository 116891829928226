.radio {
  display: flex;
  align-items: center;
  justify-content: left;
  cursor: pointer;

  .radioInput {
    display: none;
  }

  .radioButton {
    width: 1.5rem;
    height: 1.5rem;
    border: 1px solid var(--color-inputBorder);
    border-radius: 50%;
    box-sizing: border-box;
    padding: 4px;
    margin-right: 10px;
  }

  .radioButton::after {
    content: "";
    width: 100%;
    height: 100%;
    display: block;
    background: var(--color-orange);
    border-radius: 50%;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
    transform: scale(0);
  }

  .radioInput:checked + .radioButton {
    border: 1px solid var(--color-orange);
  }

  .radioInput:checked + .radioButton::after {
    transform: scale(1);
  }
}
