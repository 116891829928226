.routeList {
  width: 100%;
  height: calc(100% - 7.2rem);
  display: grid;
  grid-template-rows: 6.3rem 1fr;
  grid-template-areas:
    "header"
    "scroll";

  .routeListHeader {
    grid-area: header;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 2.4rem;
    width: 100%;
    margin-bottom: auto;
  }

  .infiniteScroll {
    grid-area: scroll;
    width: 100%;
    padding: 8px;
    overflow-y: auto;
    background-color: var(--color-white);

    .infiniteScrollInside {
      display: flex;
      flex-flow: column;
      align-items: center;
    }
  }
}
