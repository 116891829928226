.checkBox {
  width: 50%;

  input {
    appearance: none;
  }

  .text {
    position: relative;
    padding-left: 1.55em;
    height: 1em;
    line-height: 1em;
    cursor: pointer;
  }

  [type="checkbox"]:not(:checked) + .text:before,
  [type="checkbox"]:checked + .text:before {
    content: "";
    position: absolute;
    left: 0;
    width: 1em;
    height: 1em;
    border: 1px solid var(--color-inputBorder);
    border-radius: 3px;
  }

  [type="checkbox"]:not(:checked) + .text:after,
  [type="checkbox"]:checked + .text:after {
    position: absolute;
    content: url("../../assets/icons/check.svg");
    top: 0.2em;
    left: 0.2em;
    line-height: 0.8;
  }

  [type="checkbox"]:checked + .text:before {
    border: 1px solid var(--color-orange);
    background-color: var(--color-orange);
  }

  .text:hover:before {
    border: 1px solid var(--color-orange) !important;
  }
}
