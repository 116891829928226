.routeItem {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 10.4rem;
  cursor: pointer;
  transition: all 0.2s;

  .container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 1.6rem;
    height: auto;
    width: 100%;

    .routeImage {
      width: 7.2rem;
      height: 7.2rem;
      object-fit: cover;
      border-radius: 1.6rem;
      display: block;
    }

    .wrapper {
      display: flex;
      justify-content: space-between;
      width: 100%;

      .info {
        padding: 0 1.6rem;
        display: grid;
        gap: 0.8rem;

        .title {
          font-size: 1.7rem;
          margin: 0;
        }

        .tags {
          font-family: futura;
          font-size: 1.5rem;
          display: grid;
          gap: 1rem;
          grid-template-columns: repeat(auto-fit, minmax(min-content, 0));

          .tag {
            margin: 0;
          }

          .tagImage {
            display: inline-block;
            content: "";
            background: url(../../assets/icons/tag.svg) center no-repeat;
            width: 1.6rem;
            height: 1.6rem;
          }
        }
      }

      .distance {
        text-align: right;
        min-width: 6rem;
        min-height: 2rem;
        display: flex;
        justify-content: flex-end;
        align-items: center;

        * {
          margin: auto 0;
          font-weight: 600;
          font-size: 1.4rem;
          color: var(--color-input);
        }
      }
    }
  }
}

.selected {
  background: #f0f2f7;
  border-radius: 16px;
}
