.dropdownBox {
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.08);
  background-color: var(--color-white);
  border-radius: 8px;
  min-width: 150px;
  padding: 15px;
  position: absolute;
  margin-top: 8px;
  z-index: 5;

  font-size: 14px;

  .bottom {
    margin-top: 5px;
    display: flex;
    justify-content: space-between;

    img {
      cursor: pointer;
    }
  }
}

.sortingMethods,
.ratingOptions {
  white-space: nowrap;
  display: grid;
  gap: 10px;
}

.ratingOptions {
  .stars {
    display: grid;
    gap: 0 3.4px;
    grid-template-columns: repeat(6, 1fr);

    span {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
