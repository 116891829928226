.headerWrapper {
  position: relative;
  padding: 1.6rem 2.4rem;
  background-color: var(--color-white);
  border-bottom: 0.1rem solid var(--color-frame);
  width: 100%;
  height: 7.2rem;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;

  .back {
    position: absolute;
    top: 50%;
    left: 2.4rem;
    transform: translateY(-50%);
  }

  h2 {
    padding: 0 3rem;
  }

  .icon {
    position: absolute;
    top: 50%;
    right: 2.4rem;
    transform: translateY(-50%);
  }
}
